export default {
  dapp: {
    list: {
      header: {
        rank: "第",
        name: "名称",
        voteReceived: "获得投票",
        blockProducedCount: "产出区块数量",
        transactionProcessedCount: "产出交易数量"
      }
    }
  }
}
