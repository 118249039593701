export default {
  forum: {
    post: {
      title: "Forum"
    },
    proposition: {
      title: "Proposition",
      list: {
        empty: "Loading",
        header: {
          proposition: "Proposition",
          voteCount: "Vote count"
        }
      }
    }
  }
}
