import * as React from "react"
import { color as color_, fontSize } from "styled-system"
import { Link } from "react-router-dom"
import { Cell, Grid } from "../../atoms/ui-grid/ui-grid.component"
import { Links } from "../../routes"
import { t } from "i18next"
import { styled } from "../../theme"
import { Config } from "../../models/config"
import { Box, Text } from "@dfuse/explorer"
import { Img } from "../../atoms/img"

const LogoElement: React.ComponentType<any> = styled.div`
  font-family: "Lato", sans-serif;
  font-weight: 600;
  ${color_};
  ${fontSize};
  position: relative;

  @media (max-width: 767px) {
    top: -6px;
  }
`

const Tagline: React.ComponentType<any> = styled.span`
  font-family: "Lato", sans-serif;
  font-weight: 600;
  color: ${(props) => props.theme.colors.logo1};
  ${fontSize};
  letter-spacing: 1px;
`

const LogoLink: React.ComponentType<any> = styled(Link)`
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface Props {
  variant: "dark" | "light"
}

export const HeaderLogo: React.FC<Props> = (props) => {
  // console.log(props.variant)
  return (
    <Grid gridTemplateColumns={["auto auto"]} gridRow={["1"]} gridColumn={["1"]} py={[1, 0]}>
      <Cell alignSelf="center" justifySelf="right">
        <LogoLink to={Links.home()}>
          <Logo variant={props.variant} />
        </LogoLink>
      </Cell>
      <Cell pl={[0, 1, 3]} mr={[1, 2]} alignSelf="center" justifySelf="left">
        <Tagline color="#fff" fontWeight="400">
          <Cell display={["none", "block"]} fontSize={[0, 1, 2]}>
            {t("core.tagline")}
          </Cell>
          <Cell display={["none", "block"]} fontSize={[0, 1, 2]}>
            {t("core.tagline2")}
          </Cell>
        </Tagline>
      </Cell>
    </Grid>
  )
}

const Logo: React.FC<Props> = (props) => {
  const { network } = Config
  // console.log(1,props.variant)
  // console.log(network)
  if (network?.logo) {
    if (network.logo_text) {
      return <LogoImageAndText image={network.logo} text={network.logo_text} variant={props.variant} />
    }

    // return <LogoImage image={network.logo} />
  }

  return <LogoDefault />
}

const LogoDefault: React.FC<{thmem?: string}> = ({thmem}) => {
  // console.log("theme:",thmem);
  let color = thmem === 'light' ? 'white' : 'black' 
  let fontSize = thmem === 'light' ? ["36px", "42px", "42px"] : ["24px", "24px"]
  return <>
    <LogoElement px={[0]} color="logo2" fontSize={fontSize}>
    D
    </LogoElement>
    <LogoElement px={[0]} color={color} fontSize={fontSize}>
      CC
    </LogoElement>
    <LogoElement px={[0]} color="logo2" fontSize={fontSize}>
    I
    </LogoElement>
    <LogoElement px={[0]} color={color} fontSize={fontSize}>
      CC
    </LogoElement>
  </>
}

// const LogoImage: React.FC<{ image: string }> = ({ image }) => (
//   <Img src={image} alt="Logo" minWidth="70px" maxHeight="70px"></Img>
// )

const LogoText = styled(Text)`
  font-family: "Lato", sans-serif;
  font-weight: 600;
`

const LogoImageAndText: React.FC<{ image: string[]; text?: string , variant?: string}> = ({ image, text, variant }) => (
  <Box pa={[0]} alignItems="center" justifyContent="center" minWidth="150px" flexWrap="wrap">
    <Img src={variant === 'light' ?  image[0] :  image[1]} alt="DCCICC 浏览器" title={text} maxWidth="500px" maxHeight="150px"></Img> 
{/*    {text ? (
      text === "DCCICC" ? (
        <Box mx={[2]}>
          <LogoDefault thmem={variant}  />
        </Box>
      ) : (
        <LogoText color="white" mx={[2]} fontSize={[4]}>
          {text}
        </LogoText>
      )
    ) : null}*/}
  </Box>
)
