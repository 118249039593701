export default {
  footer: {
    title_en: "Digital Creation Copyright Issue Consortium Chain",
    aboutDfuse: "",
    copyright: "© {{year}} DCCICC数字文创版权发行联盟链. 密钥科技（杭州）有限公司版权所有",
    privacyPolicy: "隐私政策",
    privacyPolicyLink: "/privacy",
    termsOfServices: "服务条款",
    termsOfServicesLink: "/terms"
  }
}
