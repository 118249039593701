export default {
  accountTables: {
    search: {
      scope: "规模",
      load: "读取"
    },
    tables: {
      dropdown: {
        placeholder: "选择一个表格"
      }
    }
  }
}
